<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">Servis Görevleri</div>
      </div>
      <div class="card-body p-0">
        <div class="border-top border-bottom p-1">
          <div class="row">
            <div class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Başlangıç</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.start_date"
                  :config="{
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Bitiş</small
                >
                <flat-pickr
                  class="form-control"
                  v-model="datasource.filter.end_date"
                  :config="{
                    altFormat: 'd.m.Y',
                    altInput: true,
                    dateFormat: 'Y-m-d',
                    locale: 'tr',
                  }"
                />
              </div>
            </div>
            <div class="col-sm-3">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Görev Durumu</small
                >
                <v-select
                  v-model="datasource.filter.status"
                  :options="task_status"
                  :reduce="(option) => option.value"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-3">
              
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold">Kullanıcı</small
                >
                <v-select
                  v-model="datasource.filter.staff_id"
                  :reduce="(curr) => curr.value"
                  :options="global.users"
                  :searchable="false"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-9">
              <div class="form-group">
                <small class="text-sm text-uppercase font-semibold"
                  >Görevler</small
                >
                <v-select
                  v-model="datasource.filter.task_definition_id"
                  :options="global.task_todo"
                  :reduce="(option) => option.value"
                  :searchable="true"
                  :clearable="false"
                />
              </div>
            </div>
            <div class="col-sm-2">
              <div class="form-group mt-2">
                <button class="btn btn-primary" @click="filter()">
                  Uygula
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="firstlast-padding">
          <b-table
            ref="table_service_task"
            responsive
            striped
            small
            hover
            :items="datasource.tasks"
            :fields="datasource.table.fields"
            @row-selected="setTask"
            v-model="datasource.table.selected"
            :tbody-tr-class="rowClass"
            class="mb-0 align-top"
            show-empty
          >
            <template #cell(task_type)="data">
              <div class="font-weight-bold">
                <div>{{ data.item.task_type_text }}</div>
                <small class="text-muted">{{ data.item.priority_text }}</small>
              </div>
            </template>
            <template #cell(task_definition)="data">
              <div class="font-weight-bold">
                {{ data.value }}
              </div>
            </template>
            <template #cell(device_item)="data">
              <div class="font-weight-bold">
                {{ data.value.device_name }}
              </div>
            </template>
            <template #cell(staff_item)="data">
              <div v-if="data.value != null">
                {{ data.value.user_namesurname }}
              </div>
              <div v-if="data.item.staff_item_complated != null">
                <span>Tamamlayan</span>
                <span class="ml-1">{{
                  data.item.staff_item_complated.user_namesurname
                }}</span>
              </div>
            </template>
            <template #cell(date_item)="data">
              <div>
                <div class="font-medium">Planlanan Tarih:</div>
                <div>
                  <span v-if="data.item.planned_date != ''">
                    {{
                      moment(data.item.planned_date).format("DD.MM.YYYY HH:mm")
                    }}
                  </span>
                </div>
              </div>
              <div class="mt-05rem" v-if="data.item.complated_date != null">
                <div class="font-medium">Tamamlanma Tarihi:</div>
                <div>
                  <span v-if="data.item.complated_date != ''">
                    {{
                      moment(data.item.complated_date).format(
                        "DD.MM.YYYY HH:mm"
                      )
                    }}
                  </span>
                  <span v-else>-</span>
                </div>
              </div>
            </template>
            <template #cell(status_item)="data">
              <status-badge :status="data.value" />
            </template>
            <template #empty>
              <div class="pt-3 pb-3 text-center">
                Listelenecek bir kayıt bulunmuyor.
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { tr } from "flatpickr/dist/l10n/tr.js";
import api from "@/service/api";
import utils from "@/service/utils";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    StatusBadge,
    VueAutosuggest,
    vSelect,
    flatPickr,
  },
  data() {
    return {
      datasource: {
        users: [],
        tasks: [],
        task: null,
        filter: {
          start_date: null,
          end_date: null,
          status: "",
          task_definition_id: "0",
          staff_id: "0",
        },
        table: {
          fields: [
            {
              key: "task_type",
              label: "Tür",
              sortable: true,
              selected: true,
            },
            {
              key: "task_name",
              label: "Görev",
              sortable: true,
              selected: true,
            },
            {
              key: "device_item",
              label: "Cihaz",
              sortable: true,
              selected: true,
            },
            {
              key: "staff_item",
              label: "Personel",
              sortable: true,
              selected: true,
            },
            {
              key: "date_item",
              label: "Tarih",
              sortable: false,
              tdClass: "minw14rem pr-0 pl-1",
              thClass: "minw14rem pr-0 pl-1",
            },
            { key: "status_item", label: "Durum", sortable: true },
          ],
          selected: null,
        },
      },
    };
  },
  methods: {
    getUsers() {
      api.action("getUsers").then((q) => {
        if (q.success) {
          this.datasource.users = q.data;
        }
      });
    },
    filter() {
      let postData = this.datasource.filter;
      api.action("getServiceTask", { ...postData }).then((q) => {
        if (!q.success) {
          utils.notificationToast("warning", "Bilgi", q.message);
          return;
        }
        this.datasource.tasks = q.data;
      });
    },
    setTask(item) {
      console.log("item", item);
    },
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }
    },
    moment(v) {
      return moment(v);
    },
  },
  created() {
    this.getUsers();
    this.datasource.filter.start_date = utils.moment().add('-1','days').format("YYYY-MM-DD");
    this.datasource.filter.end_date = utils.moment().format("YYYY-MM-DD");
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
      global: (state) => state.global,
    }),
    task_status() {
      let result = [];
      result.push({ label: "Tümü", value: "" });
      result.push(...this.global.task_status);
      return result;
    },
    task_types() {
      let result = [];
      result.push({ label: "Tümü", value: "" });
      result.push(...this.global.task_types);
      return result;
    },

    list_service_task() {
      let result = [];
      return result;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>